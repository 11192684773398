export enum AdvantageIconNameEnum {
  'virtual-care' = 'virtual-care',
  'primary-care' = 'primary-care',
  'health-systems' = 'health-systems',
  'group-practices' = 'group-practices',
  'healthcare-startups' = 'healthcare-startups',
  'urgent-care' = 'urgent-care',
  'fast' = 'fast',
  'experience' = 'experience',
  'profile' = 'profile',
  'transparent-process' = 'transparent-process',
  'team' = 'team',
  'support' = 'support',
  'physician' = 'physician',
  'physician-assistant' = 'physician-assistant',
  'nurse' = 'nurse',
  'dentist' = 'dentist',
  'social-worker' = 'social-worker',
  'mental-health-counselor' = 'mental-health-counselor',
}

export enum AdvantageIconCircleColorEnum {
  'virtual-care' = '#F6FDFF',
  'primary-care' = '#FFFCF0',
  'health-systems' = '#F6FDFF',
  'group-practices' = '#FFFCF0',
  'healthcare-startups' = '#F6FDFF',
  'urgent-care' = '#FFFCF0',
  'fast' = '#F6FDFF',
  'experience' = '#FFFCF0',
  'profile' = '#F6FDFF',
  'transparent-process' = '#FFFCF0',
  'team' = '#F6FDFF',
  'support' = '#FFFCF0',
  'physician' = '#F6FDFF',
  'physician-assistant' = '#FFFCF0',
  'nurse' = '#F6FDFF',
  'dentist' = '#FFFCF0',
  'social-worker' = '#F6FDFF',
  'mental-health-counselor' = '#FFFCF0',
}

export type AdvantageIconNameType = keyof typeof AdvantageIconNameEnum

export type AdvantageItemType = {
  iconName: AdvantageIconNameType
  name: string
  description: string
}

export type AdvantageItemsType = AdvantageItemType[]

export type AdvantageIconSizeType = 'medium' | 'large'

export type AdvantageSpaceYType = 1 | 2 | 3
