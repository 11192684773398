import { Ref } from 'vue'
import classNames from 'classnames'
import { AdvantageIconCircleColorEnum, AdvantageIconNameType } from '~/types/advantage-types'

export const useAdvantageIconClasses = ({
  name,
}: {
  name: Ref<AdvantageIconNameType>
}): {
  iconClasses: Ref<string>
  circleClasses: Ref<string>
} => {
  const baseClasses = computed<string>(() => 'advantage-icon shrink-0 relative block')

  const nameClasses = computed<string>(() =>
    classNames({
      [`--${name.value}`]: !!name.value,
    })
  )

  const circleClasses = computed(() => {
    const color = AdvantageIconCircleColorEnum[name.value]
    if (!color) return ''

    const bgColor = color === '#F6FDFF' ? 'after:bg-blue-50' : color === '#FFFCF0' ? 'after:bg-yellow-50' : ''
    if (!bgColor) return ''

    return classNames(
      bgColor,
      "after:content-[''] after:inset-0 after:absolute after:rounded-full after:w-full after:h-full after:-z-10"
    )
  })

  const iconClasses = computed<string>(() => classNames(baseClasses.value, nameClasses.value))

  return {
    iconClasses,
    circleClasses,
  }
}
